import React from "react";
import "./About.css";
import fabio from 'images/fabio.jpeg'
import claudio from 'images/claudio.jpeg'
import linkedin from 'images/linkedin_logo.svg'

const About = () => {
  return (
    <div className="container-full about">
      <div className="pageTitle">
        <h1>Sobre a Minecode</h1>
        <div className="description">
          <p>
            A Minecode é uma empresa dedicada ao desenvolvimento de soluções tecnológicas inovadoras, com foco em simplificar e modernizar experiências no setor desportivo. Fundada por Cláudio Henriques e Fábio Henriques, a Minecode combina conhecimento técnico com uma visão estratégica para criar produtos de alto impacto, como o sistema Advantage, projetado especificamente para otimizar a gestão e a experiência em courts de padel.
          </p>
          <br />
          <p>
            O sistema Advantage é uma solução integrada que utiliza hardware e software avançados para oferecer funcionalidades como contagem automática de pontos, suporte ao jogador, e relatórios detalhados para a administração dos courts. Com design intuitivo e operação confiável, o Advantage é ideal para clubes que desejam elevar o nível de serviço oferecido aos seus clientes.
          </p>
          <br />
          <p>
            A Minecode diferencia-se pela sua abordagem personalizada, trabalhando diretamente com parceiros e clientes para atender às suas necessidades específicas e garantir a máxima satisfação e eficiência operacional. O compromisso da empresa é liderar a transformação digital no desporto, com soluções modernas, escaláveis e acessíveis.
          </p>
        </div>
      </div>
      <div className="slide us">
        <div className="title">
          <h2>Founders</h2>
        </div>
        <div className="description">
          <div className="founders">
            <div className="founder" onClick={
              () => {
                window.open("https://www.linkedin.com/in/c-henriques/", "_blank");
              }
            }>
              <div className="profileImage">
                <img src={claudio} alt="Cláudio" height={300} />
              </div>
              <h2><img src={linkedin} alt="linkedin" height={30} /> Cláudio Henriques</h2>
            </div>
            <div className="founder" onClick={
              () => {
                window.open("https://www.linkedin.com/in/f%C3%A1bio-henriques/", "_blank");
              }
            }>
              <div className="profileImage">
                <img src={fabio} alt="Fábio" height={300} />
              </div>
              <h2><img src={linkedin} alt="linkedin" height={30} /> Fábio Henriques</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="slide development">
       
        <div className="description">
          <span>Transformamos as suas ideias em soluções digitais inovadoras e escaláveis:</span>
          <span>
            <b>Soluções sob medida:</b> Projetamos e desenvolvemos softwares personalizados para atender às necessidades específicas do seu negócio, desde sistemas de gestão internos até aplicações e plataformas digitais.
          </span>
          <span>
            <b>Integrações e automações:</b> Conectamos diferentes sistemas para melhorar os processos operacionais, eliminando redundâncias e maximizando a eficiência.
          </span>
          <span><b>Foco na experiência do utilizador (UX/UI):</b> Criamos interfaces intuitivas e funcionais, garantindo uma experiência perfeita para seus utilizadores.</span>
        </div>
        <div className="title">
          <h2>Desenvolvimento à medida</h2>
        </div>
      </div>
      <div className="slide consulting">
        <div className="title">
          <h2>Consultoria informática</h2>
        </div>
        <div className="description">
          <span>O nosso objetivo é ajudar empresas a explorar o potencial da tecnologia para impulsionar os seus negócios:</span>
          <span>
            <b>Análise estratégica de TI:</b> Avaliamos sua infraestrutura tecnológica atual e recomendamos melhorias alinhadas aos seus objetivos.
          </span>
          <span>
            <b>Planeamento e implementação de soluções:</b> Desde a escolha de ferramentas até a implementação, acompanhamos cada etapa para assegurar o sucesso do projeto.
          </span>
          <span><b>Segurança e conformidade:</b> ferecemos suporte para proteger seus dados e garantir conformidade com regulamentações como a GDPR.</span>
        </div>
      </div>
    </div>
  );
};

export default About;
